import React from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Route, Switch } from "react-router-dom";
import "assets/vendors/style";
import "styles/wieldy.less";

import configureStore, { history } from "./appRedux/store";
import App from "./containers/App/index";
import { AuthProvider } from "./authentication";

const store = configureStore();
const queryClient = new QueryClient();
const NextApp = () => (
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <AuthProvider>
          <Switch>
            <Route path="/" component={App} />
          </Switch>
        </AuthProvider>
      </ConnectedRouter>
    </Provider>
  </QueryClientProvider>
);

export default NextApp;
