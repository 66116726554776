import React from "react";
import { Route, Switch } from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route
        path={`${match.url}home`}
        component={asyncComponent(() => import("./SamplePage"))}
      />
      <Route
        path={`${match.url}users`}
        component={asyncComponent(() => import("./Users"))}
      />
      <Route
        path={`${match.url}userVehicles/:userId`}
        component={asyncComponent(() => import("../components/userVehicles"))}
      />
      <Route
        exact
        path={`${match.url}vehicles`}
        component={asyncComponent(() => import("./Vehicles"))}
      />
      <Route
        exact
        path={`${match.url}addVehicle`}
        component={asyncComponent(() => import("./Vehicles/AddVehicleCustom"))}
      />
      <Route
        exact
        path={`${match.url}editVehicle/:id`}
        component={asyncComponent(() => import("./Vehicles/EditVehicleCustom"))}
      />
      <Route
        exact
        path={`${match.url}parts`}
        component={asyncComponent(() => import("./Parts"))}
      />
      <Route
        exact
        path={`${match.url}parts/:partsId`}
        component={asyncComponent(() => import("./Parts/details"))}
      />
      <Route
        exact
        path={`${match.url}vehicles/:vehicleId`}
        component={asyncComponent(() => import("./Vehicles/detail"))}
      />
      <Route
        exact
        path={`${match.url}packages`}
        component={asyncComponent(() => import("./Package"))}
      />
      <Route
        exact
        path={`${match.url}discounts`}
        component={asyncComponent(() => import("./Discount"))}
      />
      <Route
        exact
        path={`${match.url}videos`}
        component={asyncComponent(() => import("./Video"))}
      />
    </Switch>
  </div>
);

export default App;
